import React from "react";
import StoryPage from "../../components/story-page";
import { Footer } from "../../components/footer";
/** @jsx jsx */
import { jsx } from "theme-ui";

const EmbeddedYouTube = (url, title) => {
  return (
    <section className="aw-bg-blue" sx={{ textAlign: "center" }}>
      <div className="aw-content">
        <h2 sx={{ textAlign: "left", fontWeight: "bold" }}>{title}</h2>
        <div
          className="iframe-container"
          sx={{
            maxHeight: "600px",
          }}
        >
          <iframe
            title="youtube-slideshow"
            src={url}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
};

const EmbeddedHostedVideo = (url, title) => {
  return (
    <section className="aw-bg-blue" sx={{ textAlign: "center" }}>
      <div className="aw-content">
        <h2 sx={{ textAlign: "left", fontWeight: "bold" }}>{title}</h2>
        <div
          className="video-embed-container"
          sx={{
            maxHeight: "600px",
            marginBottom: "40px",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <video
            controls
            allowFullScreen
            name="media"
            sx={{ maxWidth: "100%" }}
          >
            <source src={url} type="video/mp4" />
          </video>
        </div>
      </div>
    </section>
  );
};

const ThisPage = ({ location }) => {
  const imgUrl =
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2FDs0ZPBVFv0cOXxiy1kut%2Fb285cd-b6e8-ce7-0c72-2a551083efa6?alt=media&token=7a4774a6-6d24-4a86-8d6e-330aef2cda64";
  const fullName = `Sara Miller Arnon`;
  const zoomUrl = "";
  const zoomPhoneNumber = "";
  const zoomMeetingId = "";
  const zoomMeetingPasscode = "";
  const birthDate = new Date(1945, 4 - 1, 13);
  const deathDate = new Date(2021, 4 - 1, 25);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 4 - 1, 28);
  const memorialTime = "12:30pm Zoom opens; 1pm ET Service begins";
  const inviteText = ``;
  const inviteChild = ``;
  const obituary = ``;
  const storiesRecordUrl = "https://stories.afterword.com/f0rq7y6n2";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rpwpmv3jse58438ftnmuk14aj6typc5h0400kxpz";
  const recordingEmbedUrl = "";
  const dropboxUrl = "";

  // full community chat
  // link

  return (
    <>
      <StoryPage
        location={location}
        imgUrl={imgUrl}
        fullName={fullName}
        zoomUrl={zoomUrl}
        zoomPhoneNumber={zoomPhoneNumber}
        zoomMeetingId={zoomMeetingId}
        zoomMeetingPasscode={zoomMeetingPasscode}
        optInviteChild={inviteChild}
        customColumnSpacing="1fr 3fr"
        birthDate={birthDate}
        deathDate={deathDate}
        birthDeathFooter={birthDeathFooter}
        memorialDate={memorialDate}
        memorialTime={memorialTime}
        inviteText={inviteText}
        obituary={obituary}
        storiesRecordUrl=""
        storiesSubmissionsUrl=""
        recordingEmbedUrl={recordingEmbedUrl}
        dropboxUrl={dropboxUrl}
        hideFooter={true}
      >
        <p>
          <strong>
            Recordings of Sara's funeral service and Zoom shiva are below, as is
            Sara’s obituary.
          </strong>
        </p>
        <p>
          Sara Miller Arnon passed away on April 25, 2021 in her home in White
          Plains, New York. Sara was surrounded by her family who loved her
          dearly.&nbsp;
        </p>
        <p>
          Sara was a one of a kind gal - deeply loved by everyone up to her
          final breath.&nbsp;
        </p>
        <p>
          When we can all be together - we will. And we will raise a toast to an
          amazing woman. For now, in lieu of a physical ceremony, the family of
          Sara invites you to a virtual service honoring her life.
        </p>
      </StoryPage>
      {EmbeddedYouTube(
        "https://www.youtube.com/embed/UCEHgqWagmo",
        "Ben's Interview With His Mom, Sara"
      )}
      {EmbeddedHostedVideo(
        "https://storage.googleapis.com/afterword-static/recordings/Sara%20Arnon.mp4",
        "Memorial Service Tribute Video"
      )}
      {EmbeddedYouTube(
        "https://www.youtube.com/embed/RXiNhmHsQ_c",
        "Shiva Tribute Video"
      )}
      {EmbeddedHostedVideo(
        "https://storage.googleapis.com/afterword-static/recordings/Sara%20Miller%20Arnon%20Service.mp4",
        "Memorial Recording"
      )}
      {EmbeddedHostedVideo(
        "https://storage.googleapis.com/afterword-static/recordings/Sara%20Arnon%20Intro.mp4",
        "Memorial Pre-Service"
      )}
      {EmbeddedYouTube(
        "https://www.youtube.com/embed/Vtl0u5FkBxo",
        "Full Community Zoom Shiva (Gallery View)"
      )}
      {EmbeddedYouTube(
        "https://www.youtube.com/embed/5WZ8UejcIo4",
        "Full Community Zoom Shiva (Speaker View)"
      )}
      <section className="aw-accent">
        <div className="aw-content">
          <h2>Full Community Zoom Shiva Public Chat</h2>
          <a
            rel="noreferrer noopener"
            href="https://storage.googleapis.com/afterword-static/recordings/Sara%20Miller%20Arnon%20Shiva%20Chat.txt"
            target="_blank"
          >
            Download the Full Shiva Chat
          </a>

          <h2>
            <strong>Sara's Obituaries</strong>
          </h2>
          <p>
            <a
              href="https://www.dignitymemorial.com/obituaries/mount-vernon-ny/sara-arnon-10166500"
              target="_blank"
              rel="noopener noreferrer"
            >
              Dignity Memorial: Sara Miller Arnon: A Warrior and Fierce Fighter
              for Early Childhood Education
            </a>
          </p>
          <p>
            <a
              href="https://www.centralmaine.com/2021/04/27/obituary-sara-miller-arnon/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sara Miller Arnon - CentralMaine.com
            </a>
          </p>

          <div className="ql-editor aw-rte">
            <p class="ql-align-center">
              <strong>
                Sara Miller Arnon: A Warrior and Fierce Fighter&nbsp;
              </strong>
            </p>
            <p class="ql-align-center">
              <strong>for Early Childhood Education</strong>
            </p>
            <p>
              <br />
            </p>

            <p>
              <span>
                Sara Miller Arnon passed away on Sunday, April 25, 2021 at her
                long-time home in White Plains, New York. Sara was a warrior for
                justice, a fierce fighter for the education of all children, and
                steadfastly dedicated to family.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                Sara was born on April 13, 1945 in Oran, Algeria. Her mother,
                Gis
              </span>
              <span style={{ color: "rgb(32, 32, 32)" }}>è</span>
              <span>
                le Baroukel Miller, was French Algerian and her father, Howard
                Miller, was from Waterville, Maine. Before her first birthday,
                Sara and family moved to Waterville, where she attended grade
                school and high school. Sara remained deeply committed to the
                Waterville, Maine region throughout her life.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                Sara attended Simmons College in Boston, Massachusetts.
                Following college, Sara found her true passion and life’s
                calling in early childhood education. Sara became a ferocious
                advocate for the right of equal access to early childhood
                education. In 1970, Sara began her teaching career at a New York
                City full-day nursery school. She later earned a Master’s degree
                in Early Childhood Education from Manhattanville College and, in
                1989, Sara joined Weekday Nursery School in New Rochelle, New
                York.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                During the next 22 years, Sara dedicated her life’s work to the
                growth, enrichment, and education of young people, in her role
                as Director of Weekday Nursery School. She also served as
                President of the White Plains PTA for several years and served
                on the Board of the Westchester Association for the Education of
                Young Children (WAEYC). Sara led initiatives to raise teaching
                standards and salaries, and also worked with manufacturers of
                children’s toys and equipment to ensure that children could
                remain safe and active. Additionally, she advocated for outdoor
                play for the young where they can use their bodies and
                imaginations with the natural settings.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                Sara enjoyed a 29-year marriage to Dan Arnon until his passing
                in 2001. They met in New York City and made a home in White
                Plains. With Dan, Sara raised three children - Joslyn, Benjamin,
                and Jeremy - who she adored.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                The ultimate loves of Sara’s life were her four grandchildren -
                Bianca, Adam, Maria, and Sienna. She doted on them and was a
                remarkably proud grandmother.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                Known to all who knew her as intensely funny, Sara was also a
                warrior. She battled multiple bouts of cancer with grace and
                dignity. Sara was also a relentless champion for social justice
                and for the equality of all people. She always made people feel
                heard and feel special. As one of Sara’s cousins remarked, “she
                was an incredible human being who managed to traverse the
                boundaries between tact and honesty, grace and humor, and
                courage and vulnerability.”&nbsp;&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                Sara was a loving woman with a sharp wit, killer intellect, a
                gift for organizing, and she was always up for the ''good
                fight," for women in politics, for civil rights and for
                children's issues.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                Sara served as a mentor to many young women, who saw Sara this
                way: “She was a unique person – a strong role model for modern
                women, smart, funny, beautiful…and she always had it together,
                no matter what life gave her.”&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                Sara is survived by her three children, four grandchildren, and
                her loving sisters, Wendy Miller and Julie Miller-Soros.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                A virtual ceremony in honor of Sara will take place via Zoom on
                Wed, April 28, 2021 at 1:00 pm EDT.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              Donations in honor of Sara can be made to her favorite
              charity,&nbsp;
              <a
                href="http://nokidhungry.org/"
                rel="noopener noreferrer"
                target="_blank"
              >
                NoKidHungry.org
              </a>{" "}
              or to her favorite synagogue,{" "}
              <a
                href="https://shalomcloud.online/contribute_no_login?enc_name=QmV0aF9Jc3JhZWxfV2F0ZXJ2aWxsZQ=="
                rel="noopener noreferrer"
                target="_blank"
              >
                Beth Israel Congregation
              </a>{" "}
              in Waterville.
            </p>
          </div>

          <h2>Order of Service</h2>
          <p>
            12:30pm ET — The Zoom opens, featuring music and a special video
            interview
          </p>
          <p>1pm ET — Memorial Service begins</p>
          <p>
            ~2pm ET — Virtual story sharing. The family encourages you to stay
            and share a story about Sara in the virtual experience
          </p>
          <p>
            <strong>SHIVA Schedule at the Arnon family home</strong>
          </p>
          <p>We are limiting the number of people due to COVID.</p>
          <p>
            <u>Weds 4/28&nbsp;@ 5:30 - 7:00PM ET</u>
          </p>
          <p>Funeral guests only</p>
          <p>
            <u>Thurs 4/29&nbsp;@ 5:30 - 7:00PM ET</u>
          </p>
          <p>Invited Local Friends (Sara’s friends &amp; NY family)</p>
          <p>
            <em>
              If extensive rain then please come on Friday instead of Thursday
            </em>
          </p>
          <p>
            <u>Friday 4/30&nbsp;@ 5:30 - 7:00PM&nbsp;ET</u>
            <strong>&nbsp;</strong>&nbsp;&nbsp;&nbsp;&nbsp;
          </p>
          <p>
            Invited Local Friends (Ben, Jeremy, and Joslyn’s local NY/Conn/NJ
            friends)
          </p>
          <p>
            <strong>Full community Virtual SHIVA&nbsp;</strong>
          </p>
          <p>
            <u>Sun 5/2&nbsp;@ 1:00- 2:30pm ET</u>
          </p>
          <p>
            <a
              href="https://zoom.us/j/98637104061?pwd=TW5JanNEMmRVV24wT0Z6RERxa25Gdz09"
              rel="noopener noreferrer"
              target="_blank"
            >
              Join Zoom Meeting
            </a>{" "}
            (<span>Meeting ID: 986 3710 4061, Passcode: 045040)</span>
          </p>
          <p>
            Donations in honor of Sara can be made to her favorite
            charity,&nbsp;
            <a
              href="http://nokidhungry.org/"
              rel="noopener noreferrer"
              target="_blank"
            >
              NoKidHungry.org
            </a>{" "}
            or to her favorite synagogue,{" "}
            <a
              href="https://shalomcloud.online/contribute_no_login?enc_name=QmV0aF9Jc3JhZWxfV2F0ZXJ2aWxsZQ=="
              rel="noopener noreferrer"
              target="_blank"
            >
              Beth Israel Congregation
            </a>{" "}
            in Waterville.
          </p>
        </div>
      </section>
      {storiesRecordUrl && (
        <section>
          <div className="aw-content">
            <h2>Share a Story</h2>
            <p sx={{ display: ["block", "block", "none"] }}>
              To share a memory, click play followed by Video, Audio, or Text,
              depending on how you want to share
            </p>
            <iframe
              title="Share a story"
              src={storiesRecordUrl
                .replace("//stories.afterword.co/", "//videoask.com/")
                .replace("//stories.afterword.com/", "//videoask.com/")}
              allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
              width="100%"
              height="600px"
              style={{ border: "none", borderRadius: "24px" }}
            ></iframe>
          </div>
        </section>
      )}
      {storiesSubmissionsUrl && (
        <section>
          <div className="aw-content">
            <h2>Shared Stories</h2>
            <iframe
              title="memory-book"
              src={storiesSubmissionsUrl
                .replace("//stories.afterword.co/", "//videoask.com/")
                .replace("//stories.afterword.com/", "//videoask.com/")}
              allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
              width="100%"
              height="600px"
              style={{ border: "none", borderRadius: "24px" }}
            ></iframe>
          </div>
        </section>
      )}
      <Footer hideSubscribe={true} />
    </>
  );
};

export default ThisPage;
